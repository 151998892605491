@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800&display=swap);
/* No CSS *//*# sourceMappingURL=index.css.map */
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #F1F1F1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font: "Montserrat", sans-serif;
  color: #1C4E80;
  font-family: "Montserrat", sans-serif;
}

h2 {
  font: "Montserrat", sans-serif;
  color: #1C4E80;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font: "Montserrat", sans-serif;
  color: #0091D6;
  font-family: "Montserrat", sans-serif;
}

h4 {
  font-family: "Montserrat", sans-serif;
  color: #1C4E80;
}

.br-spacer {
  height: 2px;
  background-color: #F1F1F1;
}

.card {
  margin-bottom: 15px;
}

.inline {
  display: inline;
}

.burger-button {
  margin-top: 30px;
}

.react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.react-paginate li {
  display: inline-block;
}

.navbar-top {
  background-color: #7E909A;
}

.navbar-top a {
  color: #FFFFFF;
}

.bm-item:hover {
  text-decoration: none;
  color: white;
  background-color: #0091D5;
}

.list-group-flush {
  border: 1px solid #000;
  margin-bottom: 15px;
}

input,
select,
textarea {
  max-width: 280px;
}

#itunes-logo {
  text-align: center;
  margin-top: 10px;
  margin-left: 25px;
  margin-bottom: 7px;
}

#android-store-logo {
  text-align: center;
  margin-top: 10px;
  margin-left: 25px;
  margin-bottom: 14px;
}

.store-logo {
  text-align: center;
  margin-left: 25px;
  margin-bottom: 7px;
}

.center-horizontal {
  position: fixed;
  left: 50%;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  color: #1C4E80;
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-top-negative-2 {
  margin-top: -10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-top-35 {
  margin-top: 35px;
}

.padding-top-15 {
  padding-top: 15px;
}

.account-info-company-name {
  font-size: 1.2em;
}

.credit-display-large {
  font-size: 2.5em;
  text-align: center;
  font-weight: 600;
}

.credit-large-label {
  font-size: 1.5em;
  text-align: center;
}

/*API docs*/
#api-docs h3 {
  font-size: 1em;
}

/*404 page*/
#not-found {
  margin-top: 30px;
}

#not-found h3 {
  font-size: 1.5em;
}

#not-found p {
  font-size: 1.2em;
  font-weight: 600;
}

#not-found h4 {
  color: #EA6A47;
}

#not-found .no-matchlist {
  list-style: none;
}

#not-found .no-matchlist li {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 600;
}

#not-found .content-section {
  margin-bottom: 35px;
}

/*HOME PAGE NAV*/
#home-login-btn {
  margin-left: 15px;
  color: #000000;
}

/*HERO UNIT HOME PAGE*/
#home {
  background-color: #F1F1F1;
}

#home h1 {
  color: #1C4E80;
  text-align: center;
  font-size: 4em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 90px;
}

#home h2 {
  color: #1C4E80;
  text-align: center;
  font-size: 2.75em;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
  line-height: 1.4;
}

#home #register-call-to-action {
  margin: 15px 0 15px 0;
  font-size: 1.1em;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 6px 8px 6px 8px;
  background-color: #EA6A47;
  border-color: #EA6A47;
}

/*SHIMMER MIT LICENSE https://github.com/zalog/placeholder-loading*/
.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 16.5px 8.25px 0px 8.25px;
  overflow: hidden;
  margin-bottom: 16.5px;
  background-color: #fff;
  border: 0px solid #e6e6e6;
  border-radius: 0px;
}

.ph-item, .ph-item *,
.ph-item ::after, .ph-item ::before {
  box-sizing: border-box;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
          animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 8.25px;
  padding-left: 8.25px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8.25px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 8.25px;
  background-color: #ced4da;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 16.5px;
}

.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.66667%;
}

.ph-col-4 {
  flex: 0 0 33.33333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66667%;
}

.ph-col-10 {
  flex: 0 0 83.33333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 16.5px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-number {
  width: 100%;
  height: 229px;
  background-color: #ced4da;
  margin-bottom: 16.5px;
  padding: 20px;
}

.ph-placeholder {
  width: 100%;
  height: 29px;
  background-color: #ced4da;
  margin-bottom: 16.5px;
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #ced4da;
  margin-bottom: 16.5px;
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
/*# sourceMappingURL=App.css.map */
.bm-burger-button {
    position: fixed;
    width: 16px;
    height: 15px;
    left: 16px;
    top: 66px;
  }

  /* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-menu {
    background: #f9fbfc;
    padding: 1.5em 0 0;
    font-size: 1.15em;
    border-right: 3px solid #cccccc;
 }

 .bm-item-list {
    color: #b8b7ad;
  }

 .bm-item {
    display: inline-block;
    border-bottom: 1px solid #cccccc;
  }

.menu-item{
  color: #000000;
  padding: 1em 0px 1em 0.5em;
  
  
}


/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=fullScreenLoading.css.map */
