.bm-burger-button {
    position: fixed;
    width: 16px;
    height: 15px;
    left: 16px;
    top: 66px;
  }

  /* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-menu {
    background: #f9fbfc;
    padding: 1.5em 0 0;
    font-size: 1.15em;
    border-right: 3px solid #cccccc;
 }

 .bm-item-list {
    color: #b8b7ad;
  }

 .bm-item {
    display: inline-block;
    border-bottom: 1px solid #cccccc;
  }

.menu-item{
  color: #000000;
  padding: 1em 0px 1em 0.5em;
  
  
}
